import i18next from 'i18next';

import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import messagesKo from './messages.ko.json';
import messagesEn from './messages.en.json';

const resources = {
    en: {
        translation: messagesEn,
        name: 'English',
    },
    ko: {
        translation: messagesKo,
        name: '한국어',
    }
};

i18next
    .use(initReactI18next)
    //.use(LanguageDetector)
    .init({
        resources,
        lng: 'en',
        fallbackLng: 'en',
        debug: true,
        keySeparator: '.',
        load: 'languageOnly',
        interpolation: {
            escapeValue: false,
        },
    });
// i18next.on('initialized', function (options) {
//     console.log('initialized', options);
// });
// i18next.on('loaded', function (loaded) {
//     console.log('loaded', loaded);
// });

export const availiableLanguages = {};
Object.keys(resources).forEach(key => {
    availiableLanguages[key] = resources[key].name;
});

export default i18next;