import React, { useEffect, useState } from 'react';
import cx from 'classnames';

import styles from './Stars.module.scss';

const Stars = () => {
    const stars = new Array(6).fill({});
    const [starIdx, setStarIdx] = useState();
    useEffect(() => {
        setInterval(() => {
            const random = Math.floor(Math.random() * 6);
            setStarIdx(random);
        }, 1500);
    }, []);
    return (
        <div className={styles.univers}>
            {stars.map((item, idx) => (
                <div key={idx} className={cx(styles.shootingstar, { [styles.active]: idx === starIdx }
                )} />))}
        </div>
    );
}

export default Stars;