import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styles from './work.module.scss';
import { withTranslation } from "react-i18next";

const Work = ({ data, t }) => {
  const post = data.markdownRemark;

  return (
    <Layout>
      <SEO
        description={post.frontmatter.subtitle}
        lang="ko"
        title={`${post.frontmatter.title} - ${post.frontmatter.subtitle}`}
      />
      <div className={styles.container}>
        <div className={styles.thumbnail} style={{ backgroundImage: `url(${post.frontmatter.thumbnail.childImageSharp.fluid.src})` }} />
        <div className={styles.summary}>
          <div className={styles.innerContainer}>
            {post.frontmatter.tags.map(item => (<span key={item} className={styles.tag}>{item}</span>))}
            <h1 className={styles.title}>{post.frontmatter.title}</h1>
            <p className={styles.subtitle}>{post.frontmatter.subtitle}</p>
            {post.frontmatter.url && (
              <a className={styles.linkBtn} href={post.frontmatter.url} target="_blank"><span>{t('work.goto')}</span><span>→</span></a>
            )}
          </div>
        </div>
        <div className={styles.body}>
          <div className={styles.content} dangerouslySetInnerHTML={{ __html: post.html }} />
        </div>

      </div>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
        url
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        subtitle
        tags
        url
        thumbnail {
          childImageSharp {
            fluid(maxWidth: 2560) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      fields {
        slug
      }
    }
  }
`

export default withTranslation()(Work);