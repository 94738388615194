import PropTypes from "prop-types"
import React, { useState } from "react"
import { navigate } from 'gatsby';
import styles from "./Header.module.scss";
import { useTranslation } from 'react-i18next';
import { availiableLanguages } from '../locale/i18n';

const Header = ({ siteTitle, handleGNBClick }) => {
	const { i18n } = useTranslation();
	const [myLang, _] = useState(i18n.language.split('-')[0]);
	console.log('header i18next.language', i18n.language, myLang);
	console.log('availiableLanguages', availiableLanguages);

	const langChange = (e) => {
		const selectedLang = e.currentTarget.value;
		i18n.changeLanguage(selectedLang);
		Object.keys(availiableLanguages).forEach(lang => {
			// /{language}/work 등 언어별 url 지정된 static page인 경우
			if (window && window.location && window.location.pathname.indexOf(`/${lang}/`) > -1) {
				const newPath = window.location.pathname.replace(lang, selectedLang);
				navigate(newPath);
			}
		});
	}

	return (
		<header className={styles.header}>
			<div className={styles.container}>
				<a href="/"><h1 className={styles.logo}>123thursday</h1></a>
				<ul className={styles.gnb}>
					<li><a href="/#work" onClick={handleGNBClick}>WORK</a></li>
					<li><a href="/#service" onClick={handleGNBClick}>SERVICE</a></li>
					<li><a href="/#about" onClick={handleGNBClick}>ABOUT</a></li>
					<li><a href="/#contact" onClick={handleGNBClick}>CONTACT</a></li>
				</ul>
				<div className={styles.langSelect}>
					<select
						onChange={e => langChange(e)}
						defaultValue={myLang}>
						{Object.keys(availiableLanguages).map(lang => (
							<option key={lang} value={lang}>
								{availiableLanguages[lang]}
							</option>
						))}
					</select>
				</div>
			</div>
		</header>
	)
}

Header.propTypes = {
	siteTitle: PropTypes.string,
}

Header.defaultProps = {
	siteTitle: ``,
}

export default Header
