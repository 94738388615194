import React, { useRef, useCallback } from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Main from "../components/Main"
import SEO from "../components/seo"
import "../locale/i18n"

const IndexPage = ({ data }) => {
  const refs = {
    work: useRef(),
    about: useRef(),
    service: useRef(),
    contact: useRef(),
  }

  const handleGNBClick = useCallback((e) => {
    e.preventDefault();
    const sectionName = e.target.hash.slice(1);
    const targetRef = refs[sectionName].current;
    const bodyRect = document.body.getBoundingClientRect();
    const elemRect = targetRef.getBoundingClientRect();
    const offset = elemRect.top - bodyRect.top;
    document.documentElement.scrollTop = offset - 120;
  }, [refs]);

  return (
    <>
      <Layout handleGNBClick={handleGNBClick}>
        <SEO title="Home" />
        <Main
          workRef={refs.work}
          aboutRef={refs.about}
          contactRef={refs.contact}
          serviceRef={refs.service}
          works={data.allMarkdownRemark.edges}
        />
      </Layout>
    </>
  );
};

export const query = graphql`
  query {
    allMarkdownRemark {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
            thumbnail {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            thumbnail_default {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`

export default IndexPage;
