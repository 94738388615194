import React from 'react';

import { Link } from "gatsby"

import styles from './Main.module.scss';
import Calendar from '../components/Calendar';
import memeberRyu from '../images/team-ryu@2x.png';
import memeberMha from '../images/team-mha@2x.png';
import memeberYoo from '../images/team-yoo@2x.png';

//import { withTranslation } from 'react-i18next';
//import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import Stars from './Stars';

const Main = ({
    workRef,
    aboutRef,
    contactRef,
    serviceRef,
    works,
}) => {
    const { t, i18n } = useTranslation();
    const languageText = i18n.language.split('-')[0];
    console.log('i18n.language', i18n);
    console.log('i18n.language, languageText', i18n.language, languageText);

    return (
        <div className={styles.container}>
            <div className={styles.visual}>
                <div className={styles.left}>
                    <div className={styles.object} />
                    <h2>WE MAKE<br />DIGITAL STUFF.</h2>
                    <p>Website Development, Design etc.</p>
                </div>
                <div className={styles.right}>
                    <Calendar />
                </div>
            </div>
            <div className={styles.workSection} ref={workRef}>
                <h2 className={styles.title}>WORK</h2>
                <ul className={styles.list}>
                    {works
                        .filter(({ node }) => node.fields.slug.indexOf(languageText + "/work") > -1)
                        .sort((a, b) => a.node.frontmatter.title.localeCompare(b.node.frontmatter.title))
                        .map(({ node }) => {
                            const thumbnailDefault = node.frontmatter.thumbnail_default;
                            const backgroundSrc = thumbnailDefault && thumbnailDefault.childImageSharp && thumbnailDefault.childImageSharp.fluid.src;
                            const thumbnail = node.frontmatter.thumbnail;
                            const hoverBackgroundSrc = thumbnail && thumbnail.childImageSharp && thumbnail.childImageSharp.fluid.src;
                            return (
                                <li key={node.id}>
                                    <Link to={node.fields.slug}>
                                        <div className={styles.card} style={{ backgroundImage: `url(${backgroundSrc})` }}>
                                            <div className={styles.cardHover} style={{ backgroundImage: `url(${hoverBackgroundSrc})` }} />
                                            <p>{node.frontmatter.title}</p>
                                        </div>
                                    </Link>
                                </li>
                            );
                        })
                    }
                </ul>
            </div>
            <div className={styles.serviceSection} ref={serviceRef}>
                <h2 className={styles.title}>SERVICE</h2>
                <h3 className={styles.copy}
                    dangerouslySetInnerHTML={{ __html: t('service.copy') }}></h3>
                <div className={styles.content}>
                    <div className={styles.wrap}>
                        <h3 className={styles.subTitle}>{t('service.area')}</h3>
                        <ul className={styles.range}>
                            <li>
                                <span className={styles.icon}></span>
                                <p className={styles.desc}
                                    dangerouslySetInnerHTML={{ __html: t('service.area_desc.1') }}></p>
                            </li>
                            <li>
                                <span className={styles.icon}></span>
                                <p className={styles.desc}
                                    dangerouslySetInnerHTML={{ __html: t('service.area_desc.2') }}></p>
                            </li>
                            <li>
                                <span className={styles.icon}></span>
                                <p className={styles.desc}
                                    dangerouslySetInnerHTML={{ __html: t('service.area_desc.3') }}></p>
                            </li>
                            <li>
                                <span className={styles.icon}></span>
                                <p className={styles.desc}
                                    dangerouslySetInnerHTML={{ __html: t('service.area_desc.4') }}></p>
                            </li>
                        </ul>
                    </div>
                    <div className={styles.wrap}>
                        <h3 className={styles.subTitle}
                            dangerouslySetInnerHTML={{ __html: t('service.specs') }}></h3>
                        <ul className={styles.tools}>
                            <li>
                                <span>java</span>
                                <span>go</span>
                                <span>python</span>
                                <span>javascript</span>
                                <span>spring</span>
                                <span>django</span>
                                <span>react</span>
                                <span>vue</span>
                            </li>
                            <li>
                                <span>Sketch</span>
                                <span>Zeplin</span>
                                <span>AdobeXD</span>
                                <span>Photoshop</span>
                                <span>Illustrator</span>
                                <span>FinalCut</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className={styles.weare}>
                    <h3 className={styles.subTitle}>{t('service.how')}</h3>
                    <ul>
                        <li><span>01</span> <span>{t('service.how_desc.1')}</span></li>
                        <li><span>02</span> <span>{t('service.how_desc.2')}</span></li>
                        <li><span>03</span> <span>{t('service.how_desc.3')}</span></li>
                    </ul>
                    <Stars />
                </div>
            </div>
            <div className={styles.aboutSection} ref={aboutRef}>
                <h2 className={styles.title}>ABOUT</h2>
                <p className={styles.desc}>{t('about.desc')}</p>
                <ul className={styles.memberList}>
                    <li>
                        <p className={styles.name}>RYU</p>
                        <img src={memeberRyu} />
                        <p>{t('about.team.ryu')}</p>
                    </li>
                    <li>
                        <p className={styles.name}>MHA</p>
                        <img src={memeberMha} />
                        <p>{t('about.team.mha')}</p>
                    </li>
                    <li>
                        <p className={styles.name}>YOO</p>
                        <img src={memeberYoo} />
                        <p>{t('about.team.yoo')}</p>
                    </li>
                </ul>
            </div>
            <div className={styles.contactSection} ref={contactRef}>
                <h2 className={styles.title}>CONTACT</h2>
                <p className={styles.desc}>Hire our team!</p>
                <a className={styles.email} href="mailto:0123thursday@gmail.com">0123thursday@gmail.com</a>
            </div>
        </div>
    );
}

export default Main;
