import React, { useEffect } from 'react';
import moment from 'moment';
import styles from './Calendar.module.scss';

const Calendar = () => {
    return (
        <div className={styles.calendar}>
            <div>
                <p className={styles.month}>{moment().format('MMM')}</p>
                <p className={styles.day}>{moment().format('D')}</p>
            </div>
        </div>
    );
}

export default Calendar;